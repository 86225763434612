// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { NgForm, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import equal from 'fast-deep-equal/es6';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take, tap } from 'rxjs/operators';
import { NotAuthorisedModalComponent } from 'src/app/ui/modal/not-authorised-modal/not-authorised-modal.component';
import { environment } from 'src/environments/environment';

export function toProperCase(s: string, firstLetterOnly = false) {
  if (firstLetterOnly) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  } else {
    return s.toLowerCase().replace(/^(.)|\s(.)/g, ($1: string) => $1.toUpperCase());
  }
}

type Key<T> = keyof T | ((x: T) => number | string);
const getter = <T>(key: Key<T>) => (typeof key !== 'function' ? (i: T) => i[key] : key);

/**
 * Improved sortBy making use of
 * {@link https://en.wikipedia.org/wiki/Schwartzian_transform.}
 *
 * @param arr array of elements. Can be null or undefined too.
 * @param key property to get value from element to sort by. Or a function that is applied on every element to compute the value to sort by.
 * @param direction sort in descending order. By default will sort in ascending order.
 * @returns new array of sorted elements.
 */
export function sortBy<T>(arr: T[] | null | undefined, key: Key<T>, direction: 'asc' | 'desc' = 'asc'): T[] {
  if (!arr) {
    return [];
  }
  const getValue = getter(key);
  const directionMultiplier = direction === 'asc' ? 1 : -1;

  // FIXME: This should not modify the array in place. Use [...arr].sort or
  // arr.toSorted but need to check whether it will break anything.
  return arr.sort((a, b) => {
    const [aValue, bValue] = [getValue(a), getValue(b)];
    return directionMultiplier * (aValue < bValue ? -1 : aValue > bValue ? 1 : 0);
  });
}

/**
 * Perform comparisons by a property of a type. Can be used with Array.sort to
 * order objects by some internal data. See
 * {@link https://en.wikipedia.org/wiki/Schwartzian_transform.}
 *
 * @param key property to get value from element to sort by, or a function that
 * is applied on every element to compute the value to sort by.
 * @param direction sort in descending order. By default will sort in ascending
 * order.
 * @returns a comparison function.
 */
export function byKey<T>(key: Key<T>, direction: 'asc' | 'desc' = 'asc'): (a: T, b: T) => number {
  const get = getter(key);
  const directionMultiplier = direction === 'asc' ? 1 : -1;
  return (a, b) => {
    const [aValue, bValue] = [get(a), get(b)];
    return directionMultiplier * (aValue < bValue ? -1 : aValue > bValue ? 1 : 0);
  };
}

/**
 * Perform comparisons by multiple properties of a type. These are applied
 * lazily in sequence, with the later ones being used to break ties.
 *
 * @param keys the properties to sort by, or functions that return the values to
 * sort by.
 * @returns a comparison function.
 */
export function byKeys<T>(...keys: Key<T>[]): (a: T, b: T) => number {
  const comparators = keys.map(k => byKey(k));
  return (a, b) => {
    for (const comparator of comparators) {
      const comparison = comparator(a, b);
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  };
}

/**
 *
 * @param arr collection/array of items.
 * @param field entity field containing value to match.
 * @param match value to match against
 * @param casing true for case sensitive match. By default it is false for case insensitive match.
 * @returns filtered collection/array of items
 */
export function filterContains<T>(arr: T[], field: string, match: string, casing = false): T[] {
  if (!match) {
    // Empty value to match. Matches everything.
    return arr;
  }
  match = casing ? match : match.toLowerCase();
  const rv = arr.filter(item => {
    const value = casing ? item[field] : item[field].toLowerCase();
    return value.includes(match);
  });
  return rv;
}

/**
 * Type representing the possible values of an indexed access type on another
 * type. https://www.typescriptlang.org/docs/handbook/2/indexed-access-types.html
 * We can use this to represent for e.g. all possible values of an enum.
 */
export type ValueOf<T> = T[keyof T];

/**
 * Type representing a dictionary where there can only be a single key and its value.
 * The dictionary key is defined by the keys of the type {@link T}.
 */
export type SingleKeyDict<T extends string | number | symbol, V> = {
  [K in T]: { [P in K]: V };
}[T];

/**
 * Get enum's key from its corresponding value.
 *
 * @param enumObj The enum.
 * @param value Value of a particular enum key.
 * @returns The corresponding enum key of the {@link value}.
 */
export function getEnumKeyFromValue<T>(enumObj: T, value: ValueOf<T>): keyof T {
  const keys = Object.keys(enumObj);
  const rv = keys.find(k => enumObj[k] === value) as keyof T;
  if (rv === undefined) {
    console.error(`Value "${value}" not in enum:`, enumObj);
    throw Error(`Invalid value "${value}" not in enum.`);
  }
  return rv;
}

/**
 * Get the index of the key for an enum where keys are declared in a specific order context.
 *
 * @param enumObject The enum.
 * @param key Value of a particular enum key.
 * @returns The corresponding index of the {@link key}.
 */
export function getOrderFromEnum<T extends Record<string, string>>(enumObject: T, key: keyof T): number {
  if (typeof key !== 'string') {
    throw Error(`Invalid key type. Expect string but got ${typeof key}.`);
  }
  return Object.keys(enumObject).indexOf(key);
}

/**
 * Create enum from its string value.
 *
 * @param enumObj String enum object.
 * @param value String value, where the value should be one of the enum's constant values.
 * @returns The enum value; undefined if {@link value} is not a valid enum constant value.
 */
export function createEnumFromStringValue<T>(enumObj: { [key: string]: string }, value: string): T | undefined {
  const values = Object.values(enumObj);
  const rv = values.includes(value) ? (value as unknown as T) : undefined;
  return rv;
}

/**
 * Return unique items by a key.
 *
 * @param items List of items/objects.
 * @param key The key value that determines uniqueness.
 * @param keepOrder By default does not preserve order of items in the result, which allows this to run faster.
 */
export function uniqueBy<T>(items: T[], key: keyof T, keepOrder = false): T[] {
  // For future improvements e.g. (multiple keys, etc), see:
  // https://stackoverflow.com/a/70406623/452685
  // https://stackoverflow.com/a/56757215
  let rv;
  if (!keepOrder) {
    rv = [...new Map(items.map(item => [item[key], item])).values()];
  } else {
    rv = items.filter((item, idx, arr) => arr.findIndex(a => a[key] === item[key]) === idx);
  }

  return rv;
}

/**
 * Mark all controls of a form as dirty, allowing the form to be marked as dirty.
 *
 * @param form The NgForm element.
 */
export function markFormAsDirty(form: NgForm) {
  // eslint-disable-next-line guard-for-in
  for (const eachControl in form.controls) {
    (form.controls[eachControl] as UntypedFormControl).markAsDirty();
  }
}

/**
 * DataTable sort direction, when sorting rows by a column(s). Used when declaring custom column sort functions.
 */
export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export function showNotAuthorisedModal(modalService: BsModalService, router: Router, title?: string): BsModalRef {
  const modalRef = modalService.show(NotAuthorisedModalComponent);
  modalRef.content.title = title;

  modalRef.onHidden.pipe(take(1)).subscribe(() => {
    // Router url is '/' when user manually enters a complete url to a route in our app in browser's url address bar;
    // but the user has no authorization to access that route via entered url.
    // Redirect user to root route.
    if (router.url === '/') {
      router.navigate(['']);
    }
  });

  return modalRef;
}

const convertObjectToKeyValue = <T>(obj: object) =>
  Object.entries(obj).map(([key, value]) => ({ key, value: value as T }));

export const convertValidationObject = (validation: object | object[] | string[], throwErrors = true) => {
  const nonFieldErrorsName = 'nonFieldErrors';

  const _getValidationValue = (property: string, value: string) => {
    const regexTest = /^this field/i; // Check pattern only from the start of string.

    if (property === nonFieldErrorsName) {
      return value;
    }

    const propName = property.replace(/_/g, ' '); // global replace all occurrences of underscore.

    return regexTest.test(value) ? value.replace(regexTest, propName) : `${propName}: ${value}`;
  };

  try {
    // use == here to match both null or undefined
    if (validation == null) {
      throw new Error("Validation Object shouldn't be null or undefined.");
    }

    let keysValues: { key: string; value: string[] }[] = [];

    if (validation && Array.isArray(validation)) {
      keysValues = validation.map((value: string | object) => {
        if (typeof value === 'object') {
          // Django Rest Framework (DRF) docs says validation errors for a field is a dict `{"field_name": ["error1", "error2", ...]}`.
          const kvs = Object.entries(value);
          return { key: kvs[0][0], value: kvs[0][1] };
        } else {
          return { key: nonFieldErrorsName, value: [value] };
        }
      });
    } else if (
      validation &&
      typeof validation === 'object' &&
      // Ensure an array is not considered as an object
      !Array.isArray(validation)
    ) {
      keysValues = convertObjectToKeyValue<string[]>(validation);
      if (!keysValues?.length) {
        throw new Error('Validation Object should have properties.');
      }
    } else {
      throw new Error(`Unexpected Validation Object type. Got (${typeof validation})`);
    }

    keysValues.forEach(({ value }) => {
      if (!Array.isArray(value)) {
        throw new Error('Validation Object properties should be arrays.');
      }
      if (!value.every(v => typeof v === 'string')) {
        throw new Error('Validation Object properties arrays should be string arrays.');
      }
    });

    return keysValues.flatMap(pair => pair.value.map(v => _getValidationValue(pair.key, v)));
  } catch (e) {
    console.log('convertValidationObject error:', e);
    if (throwErrors) {
      throw e;
    } else {
      return ['Sorry! Unable to show the error message. Please try again later.'];
    }
  }
};

/**
 * Decoded access token from Auth0.
 */
export interface AccessToken {
  permissions: string[];
}

export const decodeJwtToken = (token: string): AccessToken => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(window.atob(base64));
};

export const getRandom = <T>(array: Array<T>): T => array.sort(() => Math.random() - 0.5)[0];

export function groupBy<T, K extends keyof T>(
  array: ReadonlyArray<T> | null | undefined,
  property: K,
): (Record<K, T[K]> & { items: T[] })[] {
  if (!array) {
    return [];
  }
  return array.reduce((groups, item: T) => {
    const existingGroup = groups.find(group => group[property] === item[property]);
    if (existingGroup) {
      existingGroup.items.push(item);
    } else {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      groups.push({
        [property]: item[property],
        items: [item],
      } as Record<K, T[K]> & { items: T[] });
    }
    return groups;
  }, []);
}

export function getLongest<T, K extends T | ValueOf<T>>(array: T[], getValue: (i: T) => K = i => i as K) {
  return array.reduce((longest, current) => {
    const currentValue = getValue(current);
    return String(currentValue).length > String(longest).length ? currentValue : longest;
  }, null as K);
}

export function getLongestText<T>(array: T[], getValue?: (i: T) => ValueOf<T>) {
  return array.reduce((longest, current) => {
    const value = String(getValue ? getValue(current) : current);
    return value.length > longest.length ? value : longest;
  }, '');
}

export function padLinear([start, end]: [number, number], padding: number) {
  const dx = (end - start) * padding;
  return [start - dx, end + dx];
}
export const log = <T>(...msg: string[]) => tap((x: T): void => console.log(...msg, x));

export const deepEqual = <T, U>(a: T, b: U) => equal(a, b);

export const isDemoSite = environment?.type === 'demo';
export const isLocalSite = environment?.type === 'local';
